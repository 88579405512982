import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { StoreState } from '.';
import adminReducer from '../containers/Admin/Reducers';
import appReducer from '../containers/App/Reducers';
import authReducer from '../containers/Auth/Reducers';
import homeReducer from '../containers/Home/Reducers';
import ownershipTransferReducer from '../containers/OwnershipTransfer/Reducers';
import detailsPanelReducer from '../containers/View/Details/Reducers';
import viewReducer from '../containers/View/Reducers';
import imageReducer from './images/Reducers';
import viewReportsReducer from '../containers/View/Report/Reducers';

export default (history: History) =>
    combineReducers<StoreState>({
        router: connectRouter(history),
        app: appReducer,
        auth: authReducer,
        admin: adminReducer,
        home: homeReducer,
        images: imageReducer,
        detailsPanel: detailsPanelReducer,
        ownershipTransfer: ownershipTransferReducer,
        view: viewReducer,
        viewReports: viewReportsReducer,
    });
