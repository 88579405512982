import { AxiosError } from 'axios';
import { AsyncStatus } from '../../../common/enums';
import { GetReportResponse, ReportJobResponse } from '../../../common/interfaces/report';
import { AsyncResult } from '../../../store';
import { Actions, ActionTypes } from './Actions';

export interface ReportState {
    reportJob: AsyncResult<ReportJobResponse, AxiosError | Error>;
    reportData: AsyncResult<GetReportResponse, AxiosError | Error>;
}

const initialState: ReportState = {
    reportJob: {
        status: AsyncStatus.NOT_STARTED,
    },
    reportData: {
        status: AsyncStatus.NOT_STARTED,
    },
};

const reducer = (state: ReportState = initialState, action: Actions): ReportState => {
    switch (action.type) {
        case ActionTypes.RESET_VIEW_STATE:
            return { ...initialState };

        // Report Job
        case ActionTypes.LOAD_REPORT_JOB:
            return { ...state, reportJob: { status: AsyncStatus.IN_PROGRESS } };

        case ActionTypes.STORE_REPORT_JOB:
            return { ...state, reportJob: { status: AsyncStatus.DONE, data: action.payload } };

        case ActionTypes.FETCHING_ERROR_REPORT_JOB:
            return { ...state, reportJob: { status: AsyncStatus.ERROR, error: action.payload.error } };

        // Report Data
        case ActionTypes.FETCH_REPORT_DATA:
            return { ...state, reportData: { status: AsyncStatus.IN_PROGRESS } };

        case ActionTypes.STORE_REPORT_DATA:
            return { ...state, reportData: { status: AsyncStatus.DONE, data: action.payload } };

        case ActionTypes.FETCHING_ERROR_REPORT_DATA:
            return { ...state, reportData: { status: AsyncStatus.ERROR, error: action.payload.error } };
    }

    return state;
};

export default reducer;
