import * as React from 'react';
import { connect } from 'react-redux';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { Button } from '../../../components/Buttons';
import { ActionWithPayload } from '../../../store';
import { Actions, ActionTypes } from '../../App/Actions';
import LeftPanelType from '../LeftPanelType';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

interface DispatchProps {
    setActiveSidePanel: (panel: LeftPanelType) => ActionWithPayload<ActionTypes.SET_ACTIVE_SIDE_PANEL, LeftPanelType>;
}

interface OwnProps {
    message: string;
    classNames: string;
    excludeHomeButton?: boolean;
}

type Props = OwnProps & DispatchProps;

// Include Home button on Recents & Favorites panels, but exclude it if the Home panel is already active
export const EmptyStateLeftRail: React.SFC<Props> = (props) => {
    const languageElements = useLanguageElements();
    return (
        <div className="left-panel-empty">
            <div className={props.classNames} data-client-id={AutomationIds.LEFT_RAIL_EMPTY_STATE_MESSAGE}>
                {props.message}
            </div>
            {!props.excludeHomeButton && (
                <Button
                    onClick={() => props.setActiveSidePanel(LeftPanelType.HOME)}
                    className="btn btn-primary"
                    text={languageElements.RECENTS_ACTIVATE_HOME_PANEL}
                />
            )}
        </div>
    );
};

const mapDispatch: DispatchProps = {
    setActiveSidePanel: Actions.setActiveSidePanel,
};

export default connect<null, DispatchProps>(null, mapDispatch)(EmptyStateLeftRail);
