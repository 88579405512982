import styled from '@emotion/styled';
import { Banner, BannerContent, BannerLink, Link, Modal, Spacer, TypeRampV2, sizes } from '@smartsheet/lodestar-core';
import { DownloadIcon, ExternalIcon } from '@smartsheet/lodestar-icons';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { Actions as AppActions } from '../App/Actions';
import { isDvAdminSharingUsageEnabledSelector } from '../App/Selectors';
import AdminControlsActionsBar from './AdminControlsActionsBar';
import AdminControlsDeleteModalBody from './AdminControlsDeleteModalBody';
import AdminControlsTable from './AdminControlsTable';
import AdminControlsTransferOwnershipModalBody from './AdminControlsTransferOwnershipModalBody';
import { downloadCSVFromResponse } from './AdminControlsUtils';
import { useAdminControlsViewsData } from './useAdminControlsViewsData';

enum ActionType {
    DELETE = 'delete',
    TRANSFER = 'transfer',
}

const BANNER_TRUE_UP_LINK = 'https://help.smartsheet.com/learning-track/integrations-and-add-ons/dynamic-view';

export interface Props {
    endDate: string;
    numberOfDays: number;
}

const AdminControls = (props: Props) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [selectedViews, setSelectedViews] = React.useState<Set<string>>(new Set());
    // actionItems can be set of views to delete or transfer ownership
    const [actionItems, setActionItems] = React.useState<Set<string>>(new Set());
    const [actionType, setActionType] = React.useState<ActionType>(ActionType.DELETE);
    const [searchValue, setSearchValue] = React.useState('');
    const [isReportLoading, setIsReportLoading] = React.useState(false);

    const { views, viewsTotal, handleUpdateViews, viewsLoadingStatus } = useAdminControlsViewsData(searchValue);
    const languageElements = useLanguageElements();
    const { endDate, numberOfDays } = props;
    const isDvAdminSharingUsageFeatureEnabled = useSelector(isDvAdminSharingUsageEnabledSelector);

    const handleSelectAllView = () => {
        if (selectedViews.size !== 0) {
            setSelectedViews(new Set());
        } else {
            setSelectedViews(new Set(views.keys()));
        }
    };

    const handleBulkDelete = () => {
        setShowModal(true);
        setActionType(ActionType.DELETE);
        setActionItems(new Set(selectedViews));
    };

    const handleSingleDelete = (id: string) => {
        setShowModal(true);
        setActionType(ActionType.DELETE);
        setActionItems(new Set([id]));
    };

    const handleSingleTransferOwnership = (id: string) => {
        setActionType(ActionType.TRANSFER);
        setShowModal(true);
        setActionItems(new Set([id]));
    };

    const handleSelectView = (viewId: string) => {
        const newSelected = new Set(selectedViews);

        if (newSelected.has(viewId)) {
            newSelected.delete(viewId);
        } else {
            newSelected.add(viewId);
        }

        setSelectedViews(newSelected);
    };

    const handleUpdateSelectedViews = () => {
        const newSelected = new Set(selectedViews);

        actionItems.forEach((id) => {
            if (newSelected.has(id)) {
                newSelected.delete(id);
            }
        });

        setSelectedViews(newSelected);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleViewAccessReport = () => {
        setIsReportLoading(true);
        downloadCSVFromResponse(`https://admin.smartsheet.com/api/plan_insights/v1/planinsights/reports/external/DYNAMIC_VIEW_ACCESS_REPORT`, null)
            .catch((error: Error) => dispatch(AppActions.setAppStageError(error)))
            .finally(() => setIsReportLoading(false));
    };

    const partialEndDate = endDate.split(',')[0];

    return (
        <div data-testid={AutomationIds.ADMIN_CONTROLS_CONTAINER}>
            {isDvAdminSharingUsageFeatureEnabled && (
                <Banner type="info" data-testid={AutomationIds.ADMIN_CONTROLS_TRUE_UP_BANNER} aria-label="true up banner">
                    <BannerContent>
                        <Trans
                            i18nKey="admin.controls.banner"
                            values={{
                                numberOfDays,
                                endDate,
                                partialEndDate,
                            }}
                            components={{
                                strong: <strong />,
                            }}
                        >
                            {languageElements.ADMIN_CONTROLS_TRUE_UP_BANNER(numberOfDays, endDate, partialEndDate)}
                        </Trans>
                        <BannerLink
                            href={BANNER_TRUE_UP_LINK}
                            data-testid={AutomationIds.ADMIN_CONTROLS_TRUE_UP_BANNER_LINK}
                            aria-label="true up banner link"
                        >
                            {languageElements.LEARN_MORE}
                        </BannerLink>
                        {languageElements.ADMIN_CONTROLS_TRUE_UP_BANNER_PROVISIONAL_USE}
                    </BannerContent>
                </Banner>
            )}
            <PageContainer>
                <Spacer orientation="column" space="xSmall">
                    <FlexRowContainer>
                        <Spacer orientation="column" space="xSmall">
                            <TypeRampV2 system="ursa" variant="TT-Norms-Pro--28px--400w">
                                {languageElements.ADMIN_CONTROLS_TITLE}
                            </TypeRampV2>
                            <StyledSubtitle>{languageElements.ADMIN_CONTROLS_SUBTITLE_NEW(viewsTotal)}</StyledSubtitle>
                        </Spacer>
                        {isDvAdminSharingUsageFeatureEnabled && (
                            <Spacer space="small">
                                <Link
                                    appearance={'borderless'}
                                    href={'https://admin.smartsheet.com/insights/dynamicView'}
                                    iconBefore={<ExternalIcon />}
                                    size="large"
                                >
                                    {languageElements.ADMIN_CONTROLS_VIEW_TRENDS}
                                </Link>
                                <Link
                                    appearance={'secondary'}
                                    iconBefore={<DownloadIcon />}
                                    isLoading={isReportLoading}
                                    onClick={handleViewAccessReport}
                                    size="large"
                                >
                                    {languageElements.ADMIN_CONTROLS_GET_REPORT}
                                </Link>
                            </Spacer>
                        )}
                    </FlexRowContainer>

                    <AdminControlsActionsBar
                        searchValue={searchValue}
                        onSetSearchValue={setSearchValue}
                        viewsTotal={viewsTotal}
                        selectedViewsSize={selectedViews.size}
                        isBulkDeleteDisabled={selectedViews.size === 0}
                        onBulkDelete={handleBulkDelete}
                        onSelectAllViews={handleSelectAllView}
                    />

                    <AdminControlsTable
                        views={views}
                        viewsLoadingStatus={viewsLoadingStatus}
                        selectedViews={selectedViews}
                        onSingleDelete={handleSingleDelete}
                        onSingleTransferOwnership={handleSingleTransferOwnership}
                        onSelectView={handleSelectView}
                        onViewsUpdate={handleUpdateViews}
                    />
                </Spacer>
            </PageContainer>

            <Modal width="small" placement="center" isOpen={showModal} onCloseRequested={handleHideModal}>
                {actionType === ActionType.DELETE && (
                    <AdminControlsDeleteModalBody
                        views={views}
                        itemsToDelete={actionItems}
                        onUpdateSelectedViews={handleUpdateSelectedViews}
                        onHideModal={handleHideModal}
                        onUpdateViews={handleUpdateViews}
                    />
                )}
                {actionType === ActionType.TRANSFER && (
                    <AdminControlsTransferOwnershipModalBody
                        views={views}
                        itemsToTransfer={actionItems}
                        onUpdateSelectedViews={handleUpdateSelectedViews}
                        onUpdateViews={handleUpdateViews}
                        onHideModal={handleHideModal}
                    />
                )}
            </Modal>
        </div>
    );
};

const PageContainer = styled.div`
    box-sizing: border-box;
    margin: ${sizes.large}px ${sizes.xLarge}px 0;
    position: relative;
    flex: 1 1 auto;
`;

const StyledSubtitle = styled.div`
    margin: 0;
    font-size: 13px;
    min-height: ${sizes.xxLarge}px;
    white-space: nowrap;
    overflow: hidden;
`;

const FlexRowContainer = styled.div({ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' });

export default AdminControls;
