import { AxiosError } from 'axios';
import { Filter, GetReportResponse, Pagination, ReportJobParams, ReportJobResponse } from '../../../common/interfaces/report';
import { ActionsUnion, createAction } from '../../../store';

export enum ActionTypes {
    RESET_VIEW_STATE = 'RESET_VIEW_STATE',

    // Report job actions
    LOAD_REPORT_JOB = 'LOAD_REPORT_JOB',
    STORE_REPORT_JOB = 'STORE_REPORT_JOB',
    FETCHING_ERROR_REPORT_JOB = 'FETCHING_ERROR_REPORT_JOB',

    POLL_REPORT_JOB = 'POLL_REPORT_JOB',

    // Reports paginated data actions
    FETCH_REPORT_DATA = 'FETCH_REPORT_DATA',
    STORE_REPORT_DATA = 'STORE_REPORT_DATA',
    FETCHING_ERROR_REPORT_DATA = 'FETCHING_ERROR_REPORT_DATA',
}

export const Actions = {
    // Action to reset view state
    resetViewState: () => createAction(ActionTypes.RESET_VIEW_STATE),

    // Report job actions
    loadReportJob: (params: ReportJobParams) => createAction(ActionTypes.LOAD_REPORT_JOB, params),
    storeReportJob: (response: ReportJobResponse) => createAction(ActionTypes.STORE_REPORT_JOB, response),
    fetchingErrorReportJob: (error: AxiosError | Error) => createAction(ActionTypes.FETCHING_ERROR_REPORT_JOB, { error }),
    pollReportJob: (viewId: string) => createAction(ActionTypes.POLL_REPORT_JOB, { viewId }),

    // Report data actions
    fetchReportData: (params: { viewId: string; filter?: Filter; timestamp?: number; pagination?: Pagination }) =>
        createAction(ActionTypes.FETCH_REPORT_DATA, params),
    storeReportData: (response: GetReportResponse) => createAction(ActionTypes.STORE_REPORT_DATA, response),
    fetchingErrorReportData: (error: AxiosError | Error) => createAction(ActionTypes.FETCHING_ERROR_REPORT_DATA, { error }),
};

export type Actions = ActionsUnion<typeof Actions>;
