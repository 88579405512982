import { Anchor, CellValue } from '@smartsheet/core-views-api-models';

export enum Operator {
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    CONTAINS = 'CONTAINS',
    BETWEEN = 'BETWEEN',
    TODAY = 'TODAY',
    PAST = 'PAST',
    FUTURE = 'FUTURE',
    LAST_N_DAYS = 'LAST_N_DAYS',
    NEXT_N_DAYS = 'NEXT_N_DAYS',
    IS_BLANK = 'IS_BLANK',
    IS_NOT_BLANK = 'IS_NOT_BLANK',
    IS_NUMBER = 'IS_NUMBER',
    IS_NOT_NUMBER = 'IS_NOT_NUMBER',
    IS_DATE = 'IS_DATE',
    IS_NOT_DATE = 'IS_NOT_DATE',
    IS_CHECKED = 'IS_CHECKED',
    IS_UNCHECKED = 'IS_UNCHECKED',
    IS_ONE_OF = 'IS_ONE_OF',
    IS_NOT_ONE_OF = 'IS_NOT_ONE_OF',
    LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
    GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
    NOT_BETWEEN = 'NOT_BETWEEN',
    NOT_TODAY = 'NOT_TODAY',
    NOT_PAST = 'NOT_PAST',
    NOT_FUTURE = 'NOT_FUTURE',
    NOT_LAST_N_DAYS = 'NOT_LAST_N_DAYS',
    NOT_NEXT_N_DAYS = 'NOT_NEXT_N_DAYS',
    // Multi-contact columns
    HAS_ANY_OF = 'HAS_ANY_OF',
    HAS_NONE_OF = 'HAS_NONE_OF',
    HAS_ALL_OF = 'HAS_ALL_OF',
    NOT_ALL_OF = 'NOT_ALL_OF',
    MULTI_IS_EQUAL = 'MULTI_IS_EQUAL',
    MULTI_IS_NOT_EQUAL = 'MULTI_IS_NOT_EQUAL',
}

export enum FilterExpressionType {
    AND = 'AND',
    OR = 'OR',
}

export enum Complexity {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    X_LARGE = 'X-LARGE',
}

export enum QueryType {
    COLUMN = 'COLUMN',
}

export interface Operand {
    dataNumeric: number;
    dataString: string;
}

export interface ColumnIdCondition {
    columnId: number;
    type: Operator;
    values: Operand[];
}

export interface ColumnIdFilterExpression {
    type: FilterExpressionType;
    expressions: ColumnIdFilterExpression[];
    conditions: ColumnIdCondition[];
}

export interface ColumnIdGroupingCriteria {
    id: string;
    order: string;
    isExpanded: boolean;
    columnId: number;
}

export interface ColumnIdAggregationCriteria {
    isExpanded: boolean;
    columnId: number;
}

export interface ColumnIdSortingCriteria {
    order: string;
    forceNullsToBottom: boolean;
    columnId: number;
}

export interface ColumnIdReportDefinition {
    filters: ColumnIdFilterExpression;
    groupingCriteria: ColumnIdGroupingCriteria[];
    aggregationCriteria: ColumnIdAggregationCriteria[];
    sortingCriteria: ColumnIdSortingCriteria[];
    reportType: string;
}

export interface ColumnOptions {
    fetchAll?: boolean;
    reportColumnIds?: number[];
}

export enum ReportStatus {
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
}

export interface ReportJobStatus {
    reportStatus: ReportStatus;
    columnOptionsStatus: ReportStatus;
}

export interface GridFilterQuery {
    queryType: QueryType;
}

export enum DataType {
    // eslint-disable-next-line id-denylist
    boolean = 'boolean',
    contact = 'contact',
    dateTime = 'dateTime',
    nullValue = 'nullValue',
    // eslint-disable-next-line id-denylist
    number = 'number',
    systemContact = 'systemContact',
    text = 'text',
}

export interface AbstractFilterValue {
    dataType: DataType;
}

export interface BooleanValueContainer {
    booleanValue: boolean;
}

export interface BooleanFilterValue extends AbstractFilterValue {
    // eslint-disable-next-line id-denylist
    dataType: DataType.boolean;
    value: BooleanValueContainer;
}

export interface ContactValueContainer {
    emailAddress: string;
    emailId: number;
    name: string;
}

export interface ContactFilterValue extends AbstractFilterValue {
    dataType: DataType.contact;
    value: ContactValueContainer;
}

export interface DateTimeValueContainer {
    dateTime: number;
}

export interface DateTimeFilterValue extends AbstractFilterValue {
    dataType: DataType.dateTime;
    value: DateTimeValueContainer;
}

export interface NullFilterValue extends AbstractFilterValue {
    dataType: DataType.nullValue;
}

export interface NumberValueContainer {
    numberValue: number;
}

export interface SystemContactValueContainer {
    userId: number;
    email: string;
}

export interface SystemContactFilterValue extends AbstractFilterValue {
    dataType: DataType.systemContact;
    value: SystemContactValueContainer;
}

export interface TextValueContainer {
    textValue: string;
}

export interface TextFilterValue extends AbstractFilterValue {
    dataType: DataType.text;
    value: TextValueContainer;
}

export interface NumberFilterValue extends AbstractFilterValue {
    // eslint-disable-next-line id-denylist
    dataType: DataType.number;
    value: NumberValueContainer;
}

export interface GridColumnFilterQuery extends GridFilterQuery {
    queryType: QueryType.COLUMN;
    operator: Operator;
    reportColumnId: number;
    values: AbstractFilterValue[];
}

export enum FilterJoinCriteria {
    AND = 'AND',
    OR = 'OR',
}

export interface GridFilterGroup {
    filterQueries: GridFilterQuery[];
    filterJoinCriteria: FilterJoinCriteria;
}

export interface GridFilterQueryExpression {
    filterGroups: GridFilterGroup[];
    filterJoinCriteria: FilterJoinCriteria;
}

export interface Filter {
    gridFilter: GridFilterQueryExpression;
}

export interface Pagination {
    limit: number;
    prevCursor?: string;
    nextCursor?: string;
}

export interface Format {
    format1: number;
    format2: number;
}

export interface UserAudit {
    createdAt: number;
    createdBy: number;
    modifiedAt: number;
    modifiedBy: number;
}

export interface ReportCell {
    cellValue: CellValue;
    reportColumnId: number;
    columnId: number;
    rowId: number;
    cellId: number;
    format: Format;
    conditionalFormat: Format;
    anchor: Anchor;
    overrideValidation: boolean;
}

export interface VirtualCell {
    dataString: string;
    dataNumeric: number;
    durationMillis: number;
    durationDays: number;
    sortKey: string;
    hasEmailAddress: boolean;
    gridFormat1: number;
    gridFormat2: number;
    predecessorDisplayValue: string;
    cell: ReportCell;
}

export interface ReportGroup {
    columnKey: string;
    groupKey: string;
    cell: VirtualCell;
    hierarchy: number;
    reportColumnId: number;
}

export interface ReportRow {
    id: number;
    gridId: number;
    format: Format;
    cells: ReportCell[];
    userAudit: UserAudit;
    index: number;
    reportGroups?: ReportGroup[];
}

export interface ReportJobParams {
    viewId: string;
    forceReload?: boolean;
    applyDefaultSort?: boolean;
    columnOptions?: ColumnOptions;
    contactColumnOptions?: ColumnOptions;
}

export interface ReportJobResponse {
    complexity: Complexity;
    jobId: string;
    statuses: ReportJobStatus;
    reportDefinition?: ColumnIdReportDefinition;
    timestamp?: number;
}

export interface GetReportParams {
    viewId: string;
    filter?: Filter;
    timestamp?: number;
    pagination: Pagination;
    jobId: string;
}

export interface GetReportResponse {
    viewId: string;
    viewSourceId: number;
    rows: ReportRow[];
    pagination: Pagination;
    traceId: string;
}

export interface ReportJobStatusResponse {
    statuses: ReportJobStatus;
}
