/**
 * NOTE =  Preface the title of your id/type with the filename =  FILE_NAME_IDNAME || FILE_NAME_TYPENAME and add a comment
 * above the file section for easy searching
 */
export enum AutomationIds {
    // ViewContainer
    VIEW_SEARCH_INPUT = 'vgt-1',
    VIEW_TITLE = 'vgt-2',
    VIEW_SETTINGS = 'vgt-3',
    VIEW_NEW = 'vgt-4',
    VIEW_FILTER = 'vgt-5',
    VIEW_GROUP_BY = 'vgt-6',
    VIEW_CLOSE = 'vgt-7',
    VIEW_SIDE_PANEL = 'vgt-8',
    VIEW_DELETE_BUTTON = 'vgt-9',
    VIEW_DESCRIPTION = 'vgt-10',
    VIEW_FAVORITE_ICON = 'vgt-11',

    // Admin Option Menu
    ADMIN_OPTION_MENU_DELETE_VIEW = 'apt-3',
    ADMIN_OPTION_MENU_SAVE_AS_NEW = 'aom-1',
    ADMIN_HEADER_TITLE = 'apt-1',

    // Admin Header Ids
    ADMIN_HEADER_OPTION_MENU = 'apt-2',
    ADMIN_HEADER_BACKLINK = 'apt-4',
    ADMIN_HEADER_PANEL_INDEX = 'adm-',

    // grid
    GRID_SETTINGS_BUTTON = 'grd-1',
    GRID_NO_ROW = 'nrw-1',
    GRID_ROW_STATUS_CELL = 'rsc',

    // row status
    ROW_STATUS_ERROR = 'rs-e',
    ROW_STATUS_IN_PROGRESS = 'rs-p',
    ROW_STATUS_DONE = 'rs-d',

    DETAILS_WRAP = 'dtw-1',
    DETAILS_DATA = 'dtd-1',
    // form details data
    DETAILS_TAB_DATA = 'fdd-1',
    DETAILS_TAB_DESCRIPTION = 'fdd-2',
    DETAILS_TAB_FOOTER = 'dtf-1',
    DETAILS_TAB_ERROR = 'dte-1',
    DETAILS_TAB_SAVE_ROW_ERROR = 'dts-1',
    DETAILS_TAB_GET_ROW_ERROR = 'dtg-1',
    DETAILS_TAB_GET_ROW_MESSAGE = 'dtg-2',
    DETAILS_TAB_BANNER_ROW_SAVE_FAILED = 'dtb-1',
    DETAILS_TAB_BANNER_ROW_SAVE_FAILED_TRY_AGAIN = 'dtb-2',
    DETAILS_TAB_CLEAR_BUTTON = 'dtc-1',
    DETAILS_TAB_ROW_REMOVED = 'dtrr-1',
    DETAILS_TAB_ERROR_TOAST = 'dtet-1',

    DETAILS_DATA_FOOTER_SAVE = 'ddf-1',
    DETAILS_DATA_FOOTER_CANCEL = 'ddf-2',

    // form details attachments
    DETAILS_TAB_ATTACHMENTS = 'fda-1',
    ATTACHMENT_ATTACH_BUTTON = 'fda-2',
    ATTACHMENT_ITEM_ATTACHMENT_ICON = 'fda-4',
    ATTACHMENT_ITEM_ATTACHMENT_NAME = 'fda-5',
    ATTACHMENT_ITEM_ATTACHMENT_INFO = 'fda-6',
    ATTACHMENT_ADD_CONTAINER = 'fda-7',
    ATTACHMENT_LIST_CONTAINER = 'fda-8',
    ATTACHMENT_EMPTY = 'fda-9',
    ATTACHMENT_WRAP = 'fda-10',

    // form details comments
    DETAILS_TAB_COMMENTS = 'fdc-1',
    COMMENT_COMMENTS_REPLY = 'fdc-2',
    COMMENT_COMMENTS_VIEW_PREVIOUS = 'fdc-3',
    COMMENT_COMMENTS_SEND = 'fdc-5',
    COMMENT_COMMENTS_ITEM = 'fdc-6',
    COMMENT_COMMENTS_ICON = 'fdc-7',
    COMMENT_COMMENTS_CREATOR = 'fdc-8',
    COMMENT_COMMENTS_DATE = 'fdc-9',
    COMMENT_COMMENTS_TEXT = 'fdc-10',
    COMMENT_COMMENTS_SUB_ITEM = 'fdc-11',

    DND_ITEM_HANDLE = 'dfi-1',
    DND_ITEM_ORDINAL = 'dfi-2',
    DND_ITEM_TITLE = 'dfi-3',
    ORDERED_LIST_DISPLAY_FIELD_CONTAINER = 'afc-1',

    // Available Form Fields
    AVAILABLE_FORM_FIELDS_DRAG_HANDLE = 'aff-2',
    AVAILABLE_FORM_FIELDS_ITEM_ICON = 'aff-3',
    AVAILABLE_FORM_FIELDS_TITLE = 'aff-4',
    AVAILABLE_FORM_FIELDS_PLUS_ICON = 'aff-5',

    // Form List
    FORM_LIST_DROPPABLE_CONTAINER_1 = 'fld-1',
    FORM_LIST_DROPPABLE_CONTAINER_2 = 'fld-2',
    FORM_LIST_DROPPABLE_CONTAINER_3 = 'fld-3',

    // Included Form Fields
    INCLUDED_FORM_FIELD_DRAG_HANDLE = 'iff-1',
    INCLUDED_FORM_FIELD_DELETE = 'iff-2',

    // Field Settings
    FIELD_SETTINGS_SELECT = 'fss-1',
    FIELD_SETTINGS_PICKLIST = 'fsp-1',
    FIELD_SETTINGS_MULTI_PICKLIST = 'fsm-2',
    FIELD_SETTINGS_DATESELECTOR = 'fsd-1',
    FIELD_SETTINGS_CONTACTPICKER = 'fsc-1',
    FIELD_SETTINGS_CONTAINER = 'fsc-2',
    FIELD_SETTINGS_CUSTOM_LABEL_EXPAND_COLLAPSE = 'fsc-3',
    FIELD_SETTINGS_CUSTOM_LABEL_INPUT = 'fsc-4',
    FIELD_SETTINGS_INPUT = 'fsi-1',
    FIELD_SETTINGS_DESCRIPTION_INPUT = 'fsi-2',
    FIELD_SETTINGS_FORM_ENTRY = 'fsf-1',
    FIELD_SETTINGS_TEXTNUMBER = 'fst-1',
    FIELD_SETTINGS_TOGGLE_WRAPPER_1 = 'ftw-1',
    FIELD_SETTINGS_TOGGLE_WRAPPER_2 = 'ftw-2',
    FIELD_SETTINGS_TOGGLE_WRAPPER_3 = 'ftw-3',
    FIELD_SETTINGS_PROP_LABEL = 'fsp-1',
    FIELD_SETTINGS_MULTI_CONTACT_LIST = 'fsm-1',

    // Form Rule
    FORM_RULE_WRAPPER = 'frw-',
    FORM_RULE_NAME = 'frn-9',
    FORM_RULE_CHEVRON_ICON = 'frc-1',
    FORM_RULE_MULTISELECT_1 = 'frm-1',
    FORM_RULE_MULTISELECT_2 = 'frm-2',
    FORM_RULE_BORDER_BUTTON = 'frb-1',
    FORM_RULE_MENU_BUTTON_1 = 'frm-3',
    FORM_RULE_MENU_BUTTON_2 = 'frm-4',
    FORM_RULE_MENU_BUTTON_3 = 'frm-5',
    FORM_RULE_MENU_BUTTON_4 = 'frm-6',
    FORM_RULE_BASE_HEADER_TITLE = 'frb-7',

    // Form Field Wrapper
    FORM_FIELD_WRAPPER_PICKLSIT = 'ffw-1',
    FORM_FIELD_WRAPPER_CONTACT = 'ffw-2',
    FORM_FIELD_WRAPPER_DATE = 'ffw-3',
    FORM_FIELD_WRAPPER_TEXT = 'ffw-4',
    FORM_FIELD_WRAPPER_CHECKBOX = 'ffw-5',
    FORM_FIELD_WRAPPER_ITEM_TOP = 'ffw-6',
    FORM_FIELD_WRAPPER_SPAN_REQUIRED = 'ffw-7',
    FORM_FIELD_WRAPPER_DIV_DESC = 'ffw-8',
    FORM_FIELD_WRAPPER_TEXT_BOLD = 'ffw-9',
    FORM_FIELD_WRAPPER_MULTI_PICKLSIT = 'ffw-10',
    FIELD_FIELD_MULTI_CONTACT_LIST = 'ffm-1',
    FIELD_FIELD_WRAPPER_LINK = 'hyl-1',
    CELL_IMAGE_MODAL = 'cim-1',
    FORM_FIELD_MENU_ITEM_IMAGE = 'ffmi-1',
    FORM_FIELD_CELL_IMAGE = 'ffci-1',
    FORM_FIELD_CELL_IMAGE_LOADING = 'ffcil-1',

    FORM_FIELD_MULTI_PICKLIST = 'ffmp-1',

    CONTACT_PICKER_MULTI_SELECTED_OPTION = 'cpmo-1',
    CONTACT_PICKER_SINGLE_SELECTED_OPTION = 'cpso-1',
    CONTACT_PICKER_SELECTED_OPTION_LABEL = 'cpsl-1',
    CONTACT_PICKER_SELECTED_OPTION_CLOSE_BUTTON = 'cpsc-1',
    CONTACT_PICKER_OPTION = 'cpo-1',
    CONTACT_PICKER_CLOSE_BUTTON = 'cpc-1',
    CONTACT_WITH_ICON_AND_USER = 'ctc-3',

    FIELD_PROPERTIES_CONTAINER_HEADER_SPAN = 'fpc-1',
    FIELD_PROPERTIES_CONTAINER_MUTED_SPAN = 'fpc-2',

    ADMIN_PANEL_BASIC_NAME = 'apb-1',
    ADMIN_PANEL_BASIC_DESCRIPTION = 'apb-2',
    ADMIN_PANEL_BASIC_SELECT_BUTTON = 'apb-3',
    ADMIN_PANEL_BASIC_USER_COLUMN = 'apb-4',
    ADMIN_PANEL_BASIC_NEW_SUBMISSION_YES = 'apb-5',
    ADMIN_PANEL_BASIC_NEW_SUBMISSION_NO = 'apb-6',
    ADMIN_PANEL_BASIC_NEW_DESTINATION = 'apb-7-',
    ADMIN_PANEL_BASIC_SOURCE_PICKER = 'apb-8',
    ADMIN_PANEL_BASIC_VIEWSOURCELINK = 'apb-9',
    ADMIN_PANEL_BASIC_DESTINATION_ERROR = 'apb-10',
    ADMIN_PANEL_BASIC_SHEET_FILTER = 'apb-11',

    ADMIN_PANEL_DISPLAY_DISPLAY_ATTACH = 'ado-1',
    ADMIN_PANEL_DISPLAY_ADD_ATTACH = 'ado-2',
    ADMIN_PANEL_DISPLAY_DISPLAY_COMMENTS = 'ado-3',
    ADMIN_PANEL_DISPLAY_ADD_COMMENTS = 'ado-4',
    ADMIN_PANEL_DISPLAY_DISPLAY_FILTER = 'ado-5',

    ADMIN_PANEL_PERMISSIONS_OWNER = 'app-1',
    ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER = 'app-2',
    ADMIN_PANEL_PERMISSIONS_ADMINS = 'app-3',
    ADMIN_PANEL_PERMISSIONS_USERS = 'app-4',
    ADMIN_PANEL_PERMISSIONS_DOMAINS = 'app-5',
    ADMIN_PANEL_PERMISSIONS_USERS_ERROR = 'app-6',
    ADMIN_PANEL_PERMISSIONS_DOMAINS_ERROR = 'app-7',
    ADMIN_PANEL_PERMISSIONS_GROUPS = 'app-8',
    ADMIN_PANEL_PERMISSIONS_INFO_MESSAGE = 'app-9',
    ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_BUTTON = 'app-10',
    ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_CLOSE_BUTTON = 'app-11',
    ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_CANCEL_BUTTON = 'app-12',
    ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_MODAL_LINK = 'app-13',
    ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_WARNING = 'app-14',

    ADMIN_PANEL_FORM_DETAILS_PANEL_DESCRIPTION_TOGGLE = 'apfdpdt-1',
    ADMIN_PANEL_FORM_DETAILS_PANEL_DESCRIPTION_INPUT = 'apfdpdi-1',

    BASIC_NEW_ITEM_SUBMISSION_YES = 'bni-1',
    BASIC_NEW_ITEM_SUBMISSION_NO = 'bni-2',
    BASIC_NEW_ITEM_SUBMISSION_SELECT = 'bni-3',
    ADD_DISPLAY_OPTIONS_CHECKBOX = 'ado-1',
    FIELD_SELECTOR_CHECKBOX = 'fsc-1',
    VIEW_PICKLIST = 'vpl-1',
    VIEW_WRAP_TEXT = 'vrh-1',
    VIEW_SHOW_FORMATS = 'vsf-1',
    VIEW_FILTER_WRAPPER = 'vfw-1',
    VIEW_STATUS = 'vst-1',
    VIEW_STATUS_DISMISS = 'vst-2',

    TOGGLE_WRAPPER_TOP = 'twt-1',
    DROP_DOWN_MENU_ITEM = 'drd-',
    CONTACT_SALES_BUTTON = 'csb-1',

    RADIO_YES = 'rad-1',
    RADIO_NO = 'rad-2',
    RIGHT_RAIL_SPAN = 'rrs-',

    FILTER_ADD_CONDITION_BUTTON = 'fil-1',
    FILTER_DELETE_BUTTON = 'fil-2',
    FILTER_CANCEL_BUTTON = 'fil-3',
    FILTER_APPLY_BUTTON = 'fil-4',
    FILTER_SELECT_FIELD = 'fil-6',
    FILTER_SELECT_OPERATOR = 'fil-7',
    FILTER_SELECT_DELETE = 'fil-8',
    FILTER_SELECT_INPUT = 'fil-9',
    FILTER_DISABLE_BUTTON = 'fil-10',
    FILTER_MODAL_BUTTON = 'fil-11',
    FILTER_BUTTON_GROUP = 'fil-12',
    FILTER_CONDITION_ERROR = 'fil-13',
    FILTER_CONDITION_INPUT = 'fil-14',
    FILTER_NAME = 'fil-15',
    FILTER_CONDITION_DUAL_INPUT_START = 'fil-16',
    FILTER_CONDITION_DUAL_INPUT_END = 'fil-17',
    FILTER_BASIC_DATE_INPUT = 'fil-18',
    FILTER_DUAL_DATE_INPUT = 'fil-19',
    FILTER_PREFIX = 'fil',
    UNIT_TEST_FAKE = 'tst-01',
    BUTTON_TEXT = 'btn-2',
    BUTTON_SECONDARY_TEXT = 'btn-3',
    GRID_HEADER_FILTER_BUTTON = 'gfb-1',
    VIEW_TABLE = 'gri-1',
    VIEW_COLUMN_SORT_ICON = 'gri-3',
    VIEW_COLUMN_HEADER = 'gri-2',
    FILTER_LIST_TURN_OFF_FILTER = 'fmn-1',
    FILTER_LIST_SELECT_FILTER = 'fmn-2',
    FILTER_LIST_CREATE_FILTER = 'fmn-3',
    FILTER_LIST_EDIT_FILTER = 'fmn-4',
    FILTER_LIST_DUPLICATE_FILTER = 'fmn-5',
    FILTER_LIST_DELETE_FILTER = 'fmn-6',
    FILTER_LIST_WARNING_ICON = 'fmn-7',
    FILTER_LIST_FILTER_LABEL = 'fmn-8',
    FILTER_SHARE_CHECKBOX = 'fmn-9',
    FILTER_LIST_CONTAINER = 'fmn-10',
    FILTER_LIST_SHARING_ICON = 'fmn-11',
    NOTIFICATION_CLOSE = 'tno-1',

    HIDDEN_TOKEN_RETRIEVAL_BUTTON = 'htr-1',
    HIDDEN_TOKEN_RETRIEVAL_FIELD = 'htr-2',
    HOME_NEW_VIEW = 'nvi-1',

    MAIN_NEW_VIEW = 'lnd-1',
    MAIN_DELETE_VIEW = 'lnd-2',
    MAIN_MODAL_WRAPPER = 'lnd-3',

    OWNERSHIP_TRANSFER_LIST = 'otl-1',
    OWNERSHIP_TRANSFER_TAB_DATA = 'otl-2',
    OWNERSHIP_TRANSFER_RECEIVED = 'otl-3',
    OWNERSHIP_TRANSFER_SENT = 'otl-4',
    OWNERSHIP_TRANSFER_ERROR = 'otl-5',
    OWNERSHIP_TRANSFER_ACCEPT = 'otl-6',
    OWNERSHIP_TRANSFER_CANCEL = 'otl-7',
    OWNERSHIP_TRANSFER_DECLINE = 'otl-8',
    OWNERSHIP_TRANSFER_TABLE = 'otl-9',
    OWNERSHIP_TRANSFER_HEADER = 'otl-10',
    OWNERSHIP_TRANSFER_ORG_VIEWS = 'otl-11',
    OWNERSHIP_TRANSFER = 'otl-12',
    OWNERSHIP_TRANSFER_USERS = 'otl-13',
    OWNERSHIP_TRANSFER_INFO_ON_TRANSFER = 'otl-14',
    OWNERSHIP_TRANSFER_ERROR_ON_TRANSFER = 'otl-15',

    DYNAMIC_VIEW_TITLE = 'dvt',

    HOME_LIST = 'htl-1',
    HOME_TABLE = 'htl-2',
    HOME_ROW = 'htl-3',
    HOME_HEADER = 'htl-4',
    HOME_CONTACT_SALES = 'htl-5',
    HOME_SOURCE_FILTER = 'htl-6',

    SKINNY_RAIL_HOME_BUTTON = 'srb-1',
    SKINNY_RAIL_RECENTS_BUTTON = 'srb-2',
    SKINNY_RAIL_FAVORITE_BUTTON = 'srb-3',
    SKINNY_RAIL_NEW_BUTTON = 'srb-4',
    LEFT_RAIL_EMPTY_STATE_MESSAGE = 'lpe-1',
    LEFT_PANEL_CLOSE_BUTTON = 'lpc-1',
    LEFT_PANEL_PIN_BUTTON = 'lpp-1',
    LEFT_PANEL_PIN_ICON = 'lpp-2',
    LEFT_PANEL_UNPIN_ICON = 'lpp-3',
    HOME_PANEL_TITLE = 'hpt-1',
    RECENT_PANEL_TITLE = 'rpt-1',
    FAVORITE_PANEL_TITLE = 'fpt-1',
    RECENT_ITEM_ICON = 'rii-1',
    RECENT_ITEM_LABEL = 'ril-1',
    FAVORITE_ITEM_ICON = 'fii-1',

    // SavingData
    SAVING_DATA_MODAL = 'sdm-1',

    // Admin Controls
    ADMIN_CONTROLS_CONTAINER = 'ac-1',
    ADMIN_CONTROLS_DELETE_BUTTON = 'ac-2',
    ADMIN_CONTROLS_MODAL_CONFIRM_BUTTON = 'ac-3',
    ADMIN_CONTROLS_TRANSFER_TABLE = 'ac-4',
    ADMIN_CONTROLS_OWNERSHIP_TRANSFER_USERS = 'ac-5',
    ADMIN_CONTROLS_OWNERSHIP_TRANSFER_MODAL = 'ac-6',
    ADMIN_CONTROLS_DELETE_VIEW_MODAL = 'ac-7',
    ADMIN_CONTROLS_ROW_DELETE_BUTTON = 'ac-8',
    ADMIN_CONTROLS_BULK_DELETE_BUTTON = 'ac-9',
    ADMIN_CONTROLS_ROW_OWNERSHIP_TRANSFER_BUTTON = 'ac-10',
    ADMIN_CONTROLS_TRUE_UP_BANNER = 'ac-11',
    ADMIN_CONTROLS_TRUE_UP_BANNER_LINK = 'ac-12',

    // LoadingData
    LOADING_DATA_MODAL = 'ldm-1',
}

export enum AutomationTypes {
    ATTACHMENT_ITEM_TYPE = 'fda-3',
    AVAILABLE_FORM_FIELD_TYPE = 'aff-1',
    BANNER_DANGER = 'ban-1',
    BASIC_DOWNLOAD_OPTION = 'bdo-1',
    BASIC_NEW_ITEM_SUBMISSION_SELECT = 'bni-3',
    BUTTON = 'btn-1',
    BUTTON_GROUP = 'btg-1',
    COMMENT_REPLY_ITEM_TYPE = 'fdc-12',
    CONTACT_NAME_AND_EMAIL = 'ctc-1',
    CONTACT_ICON = 'ctc-2',
    CONTAINER = 'con-1',
    DATE_PICKER = 'dpr',
    DROP_DOWN_MENU_ITEM = 'drd-1',
    FAVORITE_LIST_ITEM = 'fli',
    FIELD_SETTINGS_FORM_ENTRY = 'fsf-9',
    FILTER_LIST = 'fmc',
    FILTER_LIST_TURN_OFF_FILTER = 'fmc-1',
    FILTER_LIST_SELECT_FILTER = 'fmc-2',
    FILTER_LIST_CREATE_FILTER = 'fmc-3',
    FORM_FIELD_WRAPPER = 'ffw-1',
    FORM_LIST_INCLUDED_FORM_FIELD = 'flf-1',
    FORM_RULE_BODY = 'frb-9',
    FORM_RULE_CONTAINER = 'frc-9',
    FORM_RULE_NODE = 'frn-1',
    GRID_HEADER_CELL = 'ghc-1',
    GRID_RENDERER = 'grc-1',
    HOME_FILTER = 'hfp',
    LANDING_PAGE = 'lep-1',
    LEFT_PANEL_PIN_TYPE = 'lpt-1',
    ORDERED_LIST_DISPLAY_FIELD_ITEM = 'afc-2',
    OVERLAY = 'ovr',
    OWNERSHIP_TRANSFER_CELL = 'ott-2',
    OWNERSHIP_TRANSFER_ROW = 'ott-3',
    OWNERSHIP_TRANSFER_TAB_ITEM = 'ott-1',
    OWNERSHIP_TRANSFER_TAB_HEADER = 'oth',
    PROGRESS_INDICATOR = 'prg-1',
    REACT_SELECT_OPTION = 'rso',
    RECENT_LIST_ITEM = 'rli',
    RECENT_PINNED_LIST = 'pli',
    RECENT_UNPINNED_LIST = 'uli',
    SELECT_INPUT = 'sel-1',
    TEXT_NUMBER_COMPONENT = 'tnb-1',
    TOGGLE_ICON_CONTAINER = 'tis-3',
    TOGGLE_INPUT = 'tis-1',
    TOGGLE_LABEL = 'tis-2',
    VIEW_FILTER_CRITERIA_CONTAINER = 'vcc-1',
    VIEW_STATUS = 'sta',
    ADMIN_CONTROLS_CELL = 'act-1',
    ADMIN_CONTROLS_ROW = 'act-2',
    MAIN_FALLBACK = 'mfb-1',
}
