import { AxiosError } from 'axios';
import { ActionType, UserAnalyticsAction } from '../common/metrics/UserAnalyticsAction';
import { loggingClient } from '../http-clients/Logging.client';
import { Action } from 'redux';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { Map as ImmutableMap } from 'immutable';
import { ActionWithPayload } from './types';

const POLLING_BACKOFF_FACTOR = 1.45;

export const backoffDelayFunction = (iteration: number, initialInterval: number) => {
    return Math.pow(POLLING_BACKOFF_FACTOR, iteration) * initialInterval;
};

export const hasData = (stateItem: ImmutableMap<string, any>): boolean => {
    return stateItem && stateItem.size > 0;
};

export const convertImmutableObjectToJS = <T>(stateItem: ImmutableMap<string, any>): T | undefined => {
    return hasData(stateItem) ? (stateItem.toJS() as T) : undefined;
};

export const convertImmutableMapToJsMap = <K, V>(immutableMap: ImmutableMap<K, V>): Map<K, V> =>
    immutableMap.reduce((map, value, key) => map.set(key, value), new Map<K, V>());

export const forkEpic = <T extends Action, S>(epicFactory: Epic<T>, state$: StateObservable<S>, action: T) => {
    const actions$ = ActionsObservable.of(action);
    return epicFactory(actions$, state$, undefined);
};

export const logAndRecordSuccessMetrics = (params: {
    fileName: string;
    funcName: string;
    startTime: number;
    message: string;
    additionalInfo?: { [key: string]: string | number };
}) => {
    const { fileName, funcName, startTime, message, additionalInfo } = params;
    const duration = Date.now() - startTime;

    loggingClient.logInfo({
        file: fileName,
        func: funcName,
        message,
        duration,
        ...additionalInfo,
    });

    UserAnalyticsAction.add(ActionType.DURATION, funcName, {
        duration,
        hasError: false,
    });
};

export const logAndRecordFailureMetrics = (params: {
    fileName: string;
    funcName: string;
    startTime: number;
    error: AxiosError | Error;
    additionalInfo?: { [key: string]: string | number };
}) => {
    const { fileName, funcName, startTime, error, additionalInfo } = params;
    const duration = Date.now() - startTime;

    loggingClient.logError(fileName, funcName, error, additionalInfo);

    UserAnalyticsAction.add(ActionType.DURATION, funcName, {
        duration,
        hasError: true,
    });
};

// Utilizing TypeScript function overloading for createAction
export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P): Action<T> | ActionWithPayload<T, P> {
    return payload === undefined ? { type } : { type, payload };
}
