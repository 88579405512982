import { AsyncStatus, TrackingOperationStatus } from '../../../common/enums';
import { FormFieldInterface, TrackingResponseDto } from '../../../common/interfaces';
import { retryBackoff } from 'backoff-rxjs';
import { defer, from, timer } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ActionType, UserAnalyticsAction } from '../../../common/metrics/UserAnalyticsAction';
import { loggingClient } from '../../../http-clients/Logging.client';
import viewClient from '../../../http-clients/View.client';
import { backoffDelayFunction } from '../../../store';
import { RowUpsert } from './RowUpsert';

export const TEMP_TRACKING_ID = 'TEMP-TRACKING';
const FILENAME = 'Details/EpicUtils.ts';
const GRID_SERVICE_POLLING_INITIAL_DELAY_MS = 500;
const GRID_SERVICE_POLLING_INTERVAL_MS = 500;
const GRID_SERVICE_POLLING_MAX_INTERVAL_MS = 120000;
const GRID_SERVICE_POLLING_MAX_RETRIES = 15;

/**
 * Generate a temp tracking ID used in cases when only cell images added or upsert requests fails
 */
export const generateTempTrackingId = () => `${TEMP_TRACKING_ID}-${uuid()}`;

/**
 * Generate an array of FormFieldInterface for an update row request. This array contains previously successfully updated row fields and the newly
 * submitted row fields
 *
 * @param rowUpserts
 * @param submittedForm
 */
export const generateRowFieldsForUpdateRowRequest = (rowUpserts: RowUpsert[], submittedForm: FormFieldInterface[]): FormFieldInterface[] => {
    const updateFormFieldMap = new Map<number, FormFieldInterface>();
    rowUpserts.forEach((rowUpsert: RowUpsert) => {
        if (rowUpsert.status !== AsyncStatus.IN_PROGRESS) {
            return;
        }

        // Iterate over each row upsert in the order the user made the updates with the oldest updates being first in the array
        rowUpsert.submittedForm.forEach((formField: FormFieldInterface) =>
            // Existing row fields sent for validation only are not updated again, but used to generate the correct updated form
            updateFormFieldMap.set(formField.columnId, { ...formField, validationOnly: true })
        );
    });

    submittedForm.forEach((formField: FormFieldInterface) => updateFormFieldMap.set(formField.columnId, formField));

    return Array.from(updateFormFieldMap.values());
};

/**
 * Polls for a row upsert using a tracking ID
 *
 * @param params
 */
export const pollingRowUpdate = (params: {
    response: TrackingResponseDto;
    viewId: string;
    rowId: string;
    isNewSubmission: boolean;
    pollingDelay?: number;
}) => {
    const { response, viewId, rowId, isNewSubmission, pollingDelay = GRID_SERVICE_POLLING_INITIAL_DELAY_MS } = params;
    const { sheetId, trackingId } = response;
    let pollAttempts = 0;
    const startTime = Date.now();

    // Additional delay to make sure tracking ID exists by the time we start polling in specific scenario
    // See https://smartsheet.slack.com/archives/C014H8Q3ELX/p1659564734878599 for more info
    return timer(pollingDelay).pipe(
        mergeMap(() =>
            defer(() => from(viewClient.pollTrackingId(viewId, sheetId, trackingId))).pipe(
                tap((pollingTrackingResponse) => {
                    const messageTemplate = 'Polling attempt #';

                    loggingClient.logInfo({
                        file: FILENAME,
                        message: `${messageTemplate}${++pollAttempts}`,
                        viewId,
                        rowId,
                        sheetId,
                        trackingId,
                        operationStatus: pollingTrackingResponse.operationStatus,
                        pollAttempts,
                        messageTemplate,
                        isNewSubmission,
                    });
                }),
                map((pollingTrackingResponse) => {
                    if (pollingTrackingResponse.operationStatus !== TrackingOperationStatus.COMPLETE) {
                        // An error is thrown for the following retryBackoff operator to check if a retry should happen
                        throw new Error(pollingTrackingResponse.operationStatus);
                    }

                    return pollingTrackingResponse;
                }),
                retryBackoff({
                    initialInterval: GRID_SERVICE_POLLING_INTERVAL_MS,
                    maxInterval: GRID_SERVICE_POLLING_MAX_INTERVAL_MS,
                    backoffDelay: backoffDelayFunction,
                    resetOnSuccess: true,
                    maxRetries: GRID_SERVICE_POLLING_MAX_RETRIES,
                    shouldRetry: (error) => error && error.message === TrackingOperationStatus.INCOMPLETE,
                }),
                catchError((error: Error) => {
                    const infoMessageTemplate = 'Polling failed on attempt #';
                    loggingClient.logError(FILENAME, 'pollingRowUpdate', error, {
                        infoMessage: `${infoMessageTemplate}${pollAttempts}`,
                        infoMessageTemplate,
                        viewId,
                        rowId,
                        sheetId,
                        trackingId,
                        pollAttempts,
                        isNewSubmission,
                    });

                    // If any other error occurs, rethrow to be caught by `catchError` handler later.
                    throw error;
                }),
                tap((pollingTrackingResponse) => {
                    const duration = Date.now() - startTime;
                    UserAnalyticsAction.add(ActionType.DURATION, 'PollingComplete', {
                        duration,
                        isNewSubmission,
                    });

                    const messageTemplate = 'Polling complete on attempt #';
                    loggingClient.logInfo({
                        file: FILENAME,
                        message: `${messageTemplate}${pollAttempts}`,
                        viewId,
                        rowId,
                        sheetId,
                        trackingId,
                        operationStatus: pollingTrackingResponse.operationStatus,
                        pollAttempts,
                        messageTemplate,
                        duration,
                        isNewSubmission,
                    });
                })
            )
        )
    );
};
