import {
    FormInterface,
    Group,
    IOwnershipTransfer,
    IPaginatedResult,
    ShareError,
    SmartsheetUser,
    View,
    ViewConfig,
    ViewShareBasic,
    ViewSourceMetaData,
    ViewWithOwnerAndUserDetails,
} from '../../common/interfaces';
import { ActionsUnion, createAction } from '../../store';

export enum ActionTypes {
    FETCH_ALL_ADMIN_DATA = 'FETCH_ALL_ADMIN_DATA',

    FETCH_ADMIN_VIEW_DATA = 'FETCH_ADMIN_VIEW_DATA',
    STORE_ADMIN_VIEW_DATA = 'STORE_ADMIN_VIEW_DATA',
    UPDATE_ADMIN_VIEW_DATA = 'UPDATE_ADMIN_VIEW_DATA',

    STORE_VIEW_CONFIG = 'STORE_VIEW_CONFIG',

    UPDATE_SHARES = 'UPDATE_SHARES',
    STORE_SHARES = 'STORE_SHARES',
    STORE_SHARE_ERRORS = 'STORE_SHARE_ERRORS',

    FETCH_VIEW_SOURCE_META_DATA = 'FETCH_VIEW_SOURCE_META_DATA',
    STORE_VIEW_SOURCE_META_DATA = 'STORE_VIEW_SOURCE_META_DATA',

    FETCH_SMARTSHEET_GROUPS = 'FETCH_SMARTSHEET_GROUPS',
    STORE_SMARTSHEET_GROUPS = 'STORE_SMARTSHEET_GROUPS',

    FETCH_SMARTSHEET_USERS = 'FETCH_SMARTSHEET_USERS',
    STORE_SMARTSHEET_USERS = 'STORE_SMARTSHEET_USERS',

    FETCH_SMARTSHEET_ADMINS = 'FETCH_SMARTSHEET_ADMINS',
    STORE_SMARTSHEET_ADMINS = 'STORE_SMARTSHEET_ADMINS',

    FETCH_SMARTSHEET_USERS_BY_EMAILS = 'FETCH_SMARTSHEET_USERS_BY_EMAILS',
    STORE_SMARTSHEET_USERS_BY_EMAILS = 'STORE_SMARTSHEET_USERS_BY_EMAILS',

    CREATE_OWNERSHIP_TRANSFER = 'CREATE_OWNERSHIP_TRANSFER',
    STORE_OWNERSHIP_TRANSFER = 'STORE_OWNERSHIP_TRANSFER',

    STORE_FORM = 'STORE_FORM',
    UPDATE_FORM = 'UPDATE_FORM',
    CLEAN_FORM = 'CLEAN_FORM',

    STORE_VIEW_FILTERS = 'STORE_VIEW_FILTERS',
}

export const Actions = {
    fetchAllAdminData: (viewId: string, includeConfig: boolean, includeShares: boolean, includeForm: boolean, includeFilters: boolean) =>
        createAction(ActionTypes.FETCH_ALL_ADMIN_DATA, {
            viewId,
            includeConfig,
            includeShares,
            includeForm,
            includeFilters,
        }),

    // View
    fetchAdminViewData: (viewId: string, includeConfig: boolean, includeShares: boolean, includeForm: boolean, includeFilters: boolean) =>
        createAction(ActionTypes.FETCH_ADMIN_VIEW_DATA, {
            viewId,
            includeConfig,
            includeShares,
            includeForm,
            includeFilters,
        }),
    storeAdminViewData: (view: ViewWithOwnerAndUserDetails | undefined = undefined) => createAction(ActionTypes.STORE_ADMIN_VIEW_DATA, view),
    updateAdminViewData: (viewId: string, view: View, redirectUrl?: string) =>
        createAction(ActionTypes.UPDATE_ADMIN_VIEW_DATA, { viewId, view, redirectUrl }),

    // View Config
    storeViewConfig: (viewConfig: ViewConfig | undefined = undefined) => createAction(ActionTypes.STORE_VIEW_CONFIG, viewConfig),

    // Shares
    storeShares: (shares: ViewShareBasic[] | undefined = undefined) => createAction(ActionTypes.STORE_SHARES, shares),
    storeShareErrors: (shareErrors: ShareError[] | undefined = undefined) => createAction(ActionTypes.STORE_SHARE_ERRORS, shareErrors),
    updateShares: (viewId: string, viewShares: ViewShareBasic[], redirectUrl?: string, email?: string) =>
        createAction(ActionTypes.UPDATE_SHARES, { viewId, viewShares, redirectUrl, email }),

    // View Source Meta Data
    fetchViewSourceMetaData: (viewId: string) => createAction(ActionTypes.FETCH_VIEW_SOURCE_META_DATA, viewId),
    storeViewSourceMetaData: (sourceMetaData: ViewSourceMetaData | undefined = undefined) =>
        createAction(ActionTypes.STORE_VIEW_SOURCE_META_DATA, sourceMetaData),

    // Smartsheet Groups
    fetchSmartsheetGroups: () => createAction(ActionTypes.FETCH_SMARTSHEET_GROUPS),
    storeSmartsheetGroups: (smartsheetGroups: Group[] | undefined = undefined) => createAction(ActionTypes.STORE_SMARTSHEET_GROUPS, smartsheetGroups),

    // Smartsheet Users
    fetchSmartsheetUsers: () => createAction(ActionTypes.FETCH_SMARTSHEET_USERS),
    storeSmartsheetUsers: (smartsheetUsers: IPaginatedResult<SmartsheetUser> | undefined = undefined) =>
        createAction(ActionTypes.STORE_SMARTSHEET_USERS, smartsheetUsers),

    // Smartsheet Users by selected emails
    fetchSmartsheetUsersByEmails: () => createAction(ActionTypes.FETCH_SMARTSHEET_USERS_BY_EMAILS),
    storeSmartsheetUsersByEmails: (smartsheetUsersByEmails: IPaginatedResult<SmartsheetUser> | undefined = undefined) =>
        createAction(ActionTypes.STORE_SMARTSHEET_USERS_BY_EMAILS, smartsheetUsersByEmails),

    // Smartsheet Admins
    fetchSmartsheetAdmins: (viewId: string) => createAction(ActionTypes.FETCH_SMARTSHEET_ADMINS, viewId),
    storeSmartsheetAdmins: (smartsheetAdmins: SmartsheetUser[] | undefined = undefined) =>
        createAction(ActionTypes.STORE_SMARTSHEET_ADMINS, smartsheetAdmins),

    // Ownership Transfers
    createOwnershipTransfer: (viewId: string, email: string | undefined) => createAction(ActionTypes.CREATE_OWNERSHIP_TRANSFER, { viewId, email }),
    storeOwnershipTransfer: (pendingOwnershipTransfer: IOwnershipTransfer) =>
        createAction(ActionTypes.STORE_OWNERSHIP_TRANSFER, pendingOwnershipTransfer),

    // Form
    storeForm: (form: FormInterface | undefined = undefined) => createAction(ActionTypes.STORE_FORM, form),
    updateForm: (viewId: string, form: FormInterface, redirectUrl?: string) => createAction(ActionTypes.UPDATE_FORM, { viewId, form, redirectUrl }),
    cleanForm: () => createAction(ActionTypes.CLEAN_FORM),
};

export type Actions = ActionsUnion<typeof Actions>;
