import { HomeView } from '../../common/interfaces';
import { SortDirectionType } from 'react-virtualized';
import { ActionsUnion, createAction } from '../../store';
import { HomeTableColumn } from './HomeTableColumnData';

export enum ActionTypes {
    FETCH_HOME_DATA = 'FETCH_HOME_DATA',
    FETCH_HOME_DATA_FAILURE = 'FETCH_HOME_DATA_FAILURE',
    FETCH_HOME_DATA_SUCCESS = 'FETCH_HOME_DATA_SUCCESS',
    REMOVE_VIEW = 'REMOVE_VIEW',
    STORE_FAVORITE_VIEW = 'STORE_FAVORITE_VIEW',
    REMOVE_FAVORITE_VIEW = 'REMOVE_FAVORITE_VIEW',
    STORE_RECENT_VIEW = 'STORE_RECENT_VIEW',
    REMOVE_PINNED_VIEW = 'DELETE_PINNED_VIEW',
    STORE_PINNED_VIEW = 'STORE_PINNED_VIEW',
    SET_SORT_BY = 'SET_SORT_BY',
    SET_SORT_DIRECTION = 'SET_SORT_DIRECTION',
    SHOW_PERMISSIONS_ERROR_ON_CREATE = 'SHOW_PERMISSIONS_ERROR_ON_CREATE',
}

export const Actions = {
    fetchHomeData: () => createAction(ActionTypes.FETCH_HOME_DATA),
    fetchHomeDataFailure: () => createAction(ActionTypes.FETCH_HOME_DATA_FAILURE),
    fetchHomeDataSuccess: (views: HomeView[]) => createAction(ActionTypes.FETCH_HOME_DATA_SUCCESS, views),
    removeView: (viewId: string) => createAction(ActionTypes.REMOVE_VIEW, viewId),

    // Favorites
    storeFavoriteView: (viewId: string) => createAction(ActionTypes.STORE_FAVORITE_VIEW, viewId),
    removeFavoriteView: (viewId: string) => createAction(ActionTypes.REMOVE_FAVORITE_VIEW, viewId),

    // Recent
    storeRecentView: (viewId: string) => createAction(ActionTypes.STORE_RECENT_VIEW, viewId),

    // Pinned
    removePinnedView: (viewId: string) => createAction(ActionTypes.REMOVE_PINNED_VIEW, viewId),
    storePinnedView: (viewId: string) => createAction(ActionTypes.STORE_PINNED_VIEW, viewId),

    setSortBy: (sortBy: HomeTableColumn) => createAction(ActionTypes.SET_SORT_BY, sortBy),
    setSortDirection: (sortDirection: SortDirectionType) => createAction(ActionTypes.SET_SORT_DIRECTION, sortDirection),
    showPermissionsError: (showPermissionsError: boolean) => createAction(ActionTypes.SHOW_PERMISSIONS_ERROR_ON_CREATE, showPermissionsError),
};

export type Actions = ActionsUnion<typeof Actions>;
