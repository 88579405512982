/* eslint @typescript-eslint/no-misused-promises:"off" */
import { AxiosError } from 'axios';
import * as React from 'react';
import { ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import alertIcon from '../../assets/images/alert/icon-info-circle-bluebigger.svg';
import { ErrorType, HttpStatusCodes } from '../../common/enums';
import { AppStage } from '../../common/enums/AppStage.enum';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { ViewStatus } from '../../common/enums/ViewStatus';
import AdminPanelContainer from '../../containers/Admin';
import AdminControls from '../../containers/AdminControls/AdminControls';
import * as AppActions from '../../containers/App/Actions';
import { getViewIdFromRoute } from '../../containers/App/AppUtils';
import { HomeSourceFilter } from '../../containers/App/Reducers';
import * as appSelectors from '../../containers/App/Selectors';
import * as authSelectors from '../../containers/Auth/Selectors';
import Home from '../../containers/Home';
import * as HomeActions from '../../containers/Home/Actions';
import OwnershipTransferContainer from '../../containers/OwnershipTransfer/OwnershipTransferContainer';
import ViewContainer from '../../containers/View';
import { loggingClient } from '../../http-clients/Logging.client';
import ViewClient from '../../http-clients/View.client';
import { viewSourceClient } from '../../http-clients/ViewSource.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { ActionByType, StoreState } from '../../store';
import CreateViewRouteHandler from '../CreateView/CreateViewRouteHandler';
import withSetAppActionInProgress, { WithSetAppActionInProgressProps } from '../hoc/WithSetAppActionInProgress';
import withSetAppError, { WithSetAppErrorProps } from '../hoc/WithSetAppError';
import LandingPage from '../LandingPage';
import ModalWrapper from '../Modal';
import GenericModalContent from '../Modal/Content/GenericModalContent';
import Spinner from '../Spinner';
import MainFallback from './MainFallback';

enum GeneralErrorType {
    Network = 'Network Error',
}

interface StateProps {
    error?: any;
    stage: AppStage;
    redirectUrl: string;
    actionInProgressMessage: string;
    leftSidePanelOpened: boolean;
    inIframe: boolean;
    isUserLicensed: boolean;
    isUserSystemAdmin: boolean;
}

interface DispatchProps {
    removeView: (viewId: string) => ActionByType<HomeActions.Actions, HomeActions.ActionTypes.REMOVE_VIEW>;
    openLeftSidePanel: () => ActionByType<AppActions.Actions, AppActions.ActionTypes.OPEN_LEFT_SIDE_PANEL>;
    toggleLeftSidePanel: () => ActionByType<AppActions.Actions, AppActions.ActionTypes.TOGGLE_LEFT_SIDE_PANEL>;
    setHomeFilter: (filter: HomeFilter) => ActionByType<AppActions.Actions, AppActions.ActionTypes.SET_HOME_FILTER>;
    setHomeSourceFilter: (filter: HomeSourceFilter) => ActionByType<AppActions.Actions, AppActions.ActionTypes.SET_HOME_SOURCE_FILTER>;
}

interface ErrorDetails {
    showModal: boolean;
    title: string;
    message: string;
    childComponent?: JSX.Element;
}

export type MainProps = StateProps &
    DispatchProps &
    RouteComponentProps<{ viewId: string }> &
    WithSetAppErrorProps &
    WithSetAppActionInProgressProps &
    LanguageElementsProp;

export class Main extends React.Component<MainProps> {
    public constructor(props: MainProps) {
        super(props);
    }

    public shouldComponentUpdate(nextProps: MainProps): boolean {
        // We want to render the error, so we ensure the component does not update
        // if previous props had an error object, but current props does not.
        return !(nextProps.stage !== AppStage.Error && this.props.stage === AppStage.Error);
    }

    public UNSAFE_componentWillUpdate(nextProps: MainProps): void {
        if (nextProps.stage === AppStage.Redirect && nextProps.stage !== this.props.stage) {
            this.props.onResetAppStage();
            this.props.history.push(nextProps.redirectUrl);
        } else if (nextProps.stage === AppStage.Error && nextProps.stage !== this.props.stage) {
            this.props.onResetAppStage();
        }
    }

    public componentDidMount(): Promise<void> {
        // If app is loading home page, open the left panel
        if (this.props.location && this.props.location.pathname === '/') {
            this.props.openLeftSidePanel();

            // Set route parameters into store
            this.setHomeParameters();
        }

        this.props.onSetAppStageActionInProgress(this.props.languageElements.SPINNER_LOADING_LABEL);

        // If we are on the landing page, reset the app stage.
        // Otherwise, let each page reset app stage once they get the data they need.
        const onLandingPage = this.props.location && (this.props.location.pathname === '' || this.props.location.pathname === '/');
        if (onLandingPage) {
            this.props.onResetAppStage();
        }
        return Promise.resolve();
    }

    public render(): React.ReactNode {
        const errorDetails = this.getErrorDetails(this.props.error, this.props.inIframe);
        // In-line styling used for height to solve mobile display issues related to vh
        return (
            <>
                {(!errorDetails || errorDetails.showModal) && (
                    <ErrorBoundary FallbackComponent={MainFallback} onError={(error, info) => this.handleError(error, info)}>
                        <Switch>
                            <Route
                                exact={true}
                                path={`/views/:viewId/admin/:page(${this.getAdminPageRoutes()})`}
                                render={() => {
                                    return this.props.inIframe ? (
                                        <Redirect to={`/views/${getViewIdFromRoute(this.props.location)!}`} />
                                    ) : (
                                        <AdminPanelContainer />
                                    );
                                }}
                            />
                            <Route exact={true} path="/views/create" component={CreateViewRouteHandler} />
                            <Route exact={true} path="/views/:viewId" render={() => <ViewContainer />} />
                            <Route exact={true} path="/transfers" component={OwnershipTransferContainer} />
                            <Route
                                path={'/'}
                                exact={true}
                                render={() => {
                                    return <Home userIsLicensed={this.props.isUserLicensed} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path="/admin"
                                render={() => {
                                    if (this.props.isUserSystemAdmin) {
                                        return <AdminControls endDate="Feb 1, 2025" numberOfDays={5} />;
                                    }
                                    return <Redirect to={`/`} />;
                                }}
                            />
                            <Route path={'*'} exact={true} component={this.getNotFoundComponent} />
                        </Switch>
                    </ErrorBoundary>
                )}
                {errorDetails && !errorDetails.showModal && (
                    <LandingPage title={errorDetails.title} message={errorDetails.message} className={this.props.inIframe ? 'iframe-error' : ''}>
                        {errorDetails.childComponent}
                    </LandingPage>
                )}
                {errorDetails && errorDetails.showModal && (
                    <ModalWrapper isModalOpen={true} onClose={() => this.forceUpdate()}>
                        <GenericModalContent
                            title={errorDetails.title}
                            message={errorDetails.message}
                            onClickPrimaryButton={() => this.forceUpdate()}
                            onClickSecondaryButton={() => {}}
                            hideSecondaryButton={true}
                            icon={alertIcon}
                        />
                    </ModalWrapper>
                )}
                {this.props.stage === AppStage.ActionInProgress && (
                    <div data-client-id={AutomationIds.MAIN_MODAL_WRAPPER}>
                        <ModalWrapper isModalOpen={true} onClose={() => {}} hideCloseButton={true} focusTrap={false}>
                            <Spinner label={this.props.actionInProgressMessage} />
                        </ModalWrapper>
                    </div>
                )}
            </>
        );
    }

    public async handleDelete(): Promise<void> {
        const viewId = getViewIdFromRoute(this.props.location);
        if (!viewId) {
            return;
        }

        this.props.onSetAppStageActionInProgress(this.props.languageElements.SPINNER_DELETE_VIEW_LABEL);

        try {
            // Update history prior to deleting view to stop invalid error message showing up on completion
            // of delete request
            this.props.history.push(`/`);
            await ViewClient.updateViewStatus([viewId], ViewStatus.INACTIVE);
            this.props.onResetAppStage();

            // update redux store
            this.props.removeView(viewId);

            if (!this.props.leftSidePanelOpened) {
                this.props.toggleLeftSidePanel();
            }
        } catch (error) {
            this.props.onSetAppStageError(error);
        }
    }

    private handleError(error: Error, info: ErrorInfo) {
        loggingClient.logError('Main.tsx', this.handleError.name, error, { componentStack: info.componentStack });
    }

    // Exposed for testing
    public getErrorDetails(error: AxiosError<any, any> | undefined, inIframe = false): ErrorDetails | undefined {
        if (!error) {
            return;
        }

        if (!error.response || !error.response.data || !error.response.data.message) {
            // Handle general errors
            switch (error.message) {
                case GeneralErrorType.Network:
                    return {
                        showModal: true,
                        title: this.props.languageElements.ERRORSTATE_NETWORK_ERROR_TITLE,

                        message: this.props.languageElements.ERRORSTATE_NETWORK_ERROR_MESSAGE,
                    };
                default:
                    return {
                        showModal: true,
                        title: this.props.languageElements.ERRORSTATE_GENERIC_TITLE,

                        message: error.message || this.props.languageElements.ERRORSTATE_GENERIC_MESSAGE,
                    };
            }
        }

        // Handle custom Dynamic View errors
        switch (error.response.data.errorCode) {
            case ErrorType.SMARTSHEET_OFFLINE:
                window.location.href = '/maintenance.html';
                return;
            case ErrorType.VIEW_SOURCE_NOT_FOUND:
                return {
                    showModal: false,
                    title: this.props.languageElements.ERRORSTATE_INVALID_VIEW_TITLE,

                    message: this.getErrorMessage(error),
                    childComponent: (
                        <button onClick={() => this.handleDelete()} className="delete-button" data-client-id={AutomationIds.MAIN_DELETE_VIEW}>
                            {this.props.languageElements.MODAL_DELETE_BUTTON_TEXT}
                        </button>
                    ),
                };
            case ErrorType.VIEW_SOURCE_NON_OWNER_NOT_FOUND:
                return {
                    showModal: false,
                    title: this.props.languageElements.ERRORSTATE_INVALID_VIEW_TITLE,

                    message: this.getErrorMessage(error),
                };
            case ErrorType.VIEW_SOURCE_ROW_NOT_FOUND:
                return {
                    showModal: true,
                    title: this.props.languageElements.ERRORSTATE_NOT_FOUND_TITLE,

                    message: this.getErrorMessage(error),
                };
            case ErrorType.USER_UNAUTHORIZED_TO_VIEW_ROW:
                return {
                    showModal: true,
                    title: this.props.languageElements.ERRORSTATE_FORBIDDEN_TITLE,

                    message: this.getErrorMessage(error),
                };
            case ErrorType.VIEW_ID_INVALID:
                return {
                    showModal: false,
                    title: this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_TITLE,

                    message: this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_MESSAGE,
                };
            case ErrorType.SMARTSHEET_AUTH_ACCOUNT_BLOCKED:
                return {
                    showModal: false,
                    title: this.props.languageElements.ERRORSTATE_GENERIC_TITLE,

                    message: this.props.languageElements.ERRORSTATE_AUTH_ACCOUNT_BLOCKED,
                };
            case ErrorType.VIEW_NOT_FOUND:
                let errorDetails = {
                    showModal: false,
                    title: this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_TITLE,

                    message: this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_MESSAGE,
                };

                if (inIframe) {
                    errorDetails = {
                        showModal: false,
                        title: this.props.languageElements.MAIN_NOT_SHARED_TO_VIEW_IN_DASHBOARD_TITLE,

                        message: this.props.languageElements.MAIN_NOT_SHARED_TO_VIEW_IN_DASHBOARD_TEXT,
                    };
                }

                return errorDetails;
            case ErrorType.VIEW_INTAKE_SHEET_NOT_FOUND:
                return {
                    showModal: true,
                    title: this.props.languageElements.ERROR_HEADER,

                    message: this.getErrorMessage(error),
                };
            default:
                // Handle specific HTTP errors
                switch (error.response.status) {
                    case HttpStatusCodes.BAD_REQUEST:
                        return {
                            showModal: true,
                            title: this.props.languageElements.ERRORSTATE_BAD_REQUEST_TITLE,

                            message: this.getErrorMessage(error),
                        };
                    case HttpStatusCodes.FORBIDDEN:
                        return {
                            showModal: false,
                            title: this.props.languageElements.ERRORSTATE_FORBIDDEN_TITLE,

                            message: this.getErrorMessage(error),
                        };
                    case HttpStatusCodes.NOT_FOUND:
                        return {
                            showModal: false,
                            title: this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_TITLE,

                            message: this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_MESSAGE,
                        };
                    default:
                        return {
                            showModal: true,
                            title: this.props.languageElements.ERRORSTATE_GENERIC_TITLE,

                            message: this.getErrorMessage(error),
                        };
                }
        }
    }

    /**
     * Catch-all for the app encounters a route it does not recognize. Disable the loading
     * indicator by calling onResetAppState(), and then return the LandingPage component
     * with not found verbiage.
     *
     * TODO: re-enable once we have a page to redirect this button to
     */
    private getNotFoundComponent = (): any => {
        this.props.onResetAppStage();
        return (
            <LandingPage
                title={this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_TITLE}
                message={this.props.languageElements.ERRORSTATE_URL_NOT_FOUND_MESSAGE}
                className={this.props.inIframe ? 'iframe-error' : ''}
            >
                {/* <button className="btn btn-primary">Learn More</button> */}
            </LandingPage>
        );
    };

    private getErrorMessage(error: AxiosError<any, any>): string {
        const errorMessage = error.response && error.response.data ? error.response.data.message : undefined;

        if (typeof errorMessage === 'string') {
            return errorMessage;
        }

        if (typeof errorMessage.message === 'string') {
            return errorMessage.message;
        }

        if (Array.isArray(errorMessage.message)) {
            return this.props.languageElements.INVALID_CHANGES_MESSAGE;
        }

        loggingClient.logError('Main.tsx', 'getErrorMessage', error);

        return this.props.languageElements.ERRORSTATE_GENERIC_MESSAGE;
    }

    private getAdminPageRoutes(): string {
        const adminPages = [
            this.props.languageElements.ADMIN_PANEL_BASIC_TAB_KEY,

            this.props.languageElements.ADMIN_PANEL_DISPLAY_TAB_KEY,

            this.props.languageElements.ADMIN_PANEL_FORM_TAB_KEY,

            this.props.languageElements.ADMIN_PANEL_PERMISSIONS_TAB_KEY,
        ];

        return adminPages.join('|');
    }

    private async setHomeParameters(): Promise<void> {
        let isReport;
        let id;

        const queryParams = new URLSearchParams(this.props.location.search);

        if (queryParams.has('home')) {
            switch (queryParams.get('home')) {
                case 'all':
                    this.props.setHomeFilter(HomeFilter.ALL);
                    break;
                case 'my':
                    this.props.setHomeFilter(HomeFilter.OWNED_BY_ME);
                    break;
                case 'sharedgroup':
                    this.props.setHomeFilter(HomeFilter.SHARED_WITH_GROUP);
                    break;
                case 'sharedme':
                    this.props.setHomeFilter(HomeFilter.SHARED_WITH_ME);
                    break;
            }
        }

        if (queryParams.has('id')) {
            id = +queryParams.get('id')!;
            id = isNaN(id) ? -1 : id;
        }
        if (queryParams.has('isreport')) {
            isReport = Boolean(queryParams.get('isreport')?.toLowerCase() === 'true');
        }

        if (isReport !== undefined && id && id > -1) {
            const name = await viewSourceClient.getViewSourceName(id, isReport);
            this.props.setHomeSourceFilter({ id, isReport, name: name.data });
        }
    }
}

const mapState = createStructuredSelector<StoreState, StateProps>({
    error: appSelectors.appErrorSelector,
    stage: appSelectors.appStageSelector,
    redirectUrl: appSelectors.appRedirectUrlSelector,
    actionInProgressMessage: appSelectors.appActionInProgressMessageSelector,
    leftSidePanelOpened: appSelectors.leftSidePanelOpenedSelector,
    inIframe: appSelectors.iframeStatusSelector,
    isUserLicensed: authSelectors.userHasLicenseAccessSelector,
    isUserSystemAdmin: authSelectors.isUserSystemAdmin,
});

const mapDispatch: DispatchProps = {
    removeView: HomeActions.Actions.removeView,
    openLeftSidePanel: AppActions.Actions.openLeftSidePanel,
    toggleLeftSidePanel: AppActions.Actions.toggleLeftSidePanel,
    setHomeFilter: AppActions.Actions.setHomeFilter,
    setHomeSourceFilter: AppActions.Actions.setHomeSourceFilter,
};

export default withLanguageElementsHOC(
    withRouter(withSetAppActionInProgress(withSetAppError(connect<StateProps, DispatchProps>(mapState, mapDispatch)(Main))))
);
