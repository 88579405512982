import { GetReportParams, GetReportResponse, ReportJobParams, ReportJobResponse, ReportJobStatusResponse } from '../common/interfaces/report';
import axiosInstance from './Axios.instance';

export const reportJob = async (params: ReportJobParams): Promise<ReportJobResponse> => {
    const { viewId, forceReload, applyDefaultSort, columnOptions, contactColumnOptions } = params;
    const response = await axiosInstance.post(`/views/${viewId}/reports/reportjob`, {
        forceReload,
        applyDefaultSort,
        columnOptions,
        contactColumnOptions,
    });

    return response.data;
};

export const getReportJobStatus = async (viewId: string): Promise<ReportJobStatusResponse> => {
    const response = await axiosInstance.get(`/views/${viewId}/reports/reportjobstatus`);

    return response.data;
};

export const getReport = async (params: GetReportParams): Promise<GetReportResponse> => {
    const { viewId, filter, timestamp, pagination, jobId } = params;
    const response = await axiosInstance.post(`/views/${viewId}/reports/getreport`, {
        filter,
        timestamp,
        pagination,
        jobId,
    });

    return response.data;
};
